<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: 'middle',
  created () {
    if (!localStorage.getItem('userInfo')) {
      // 如果没有用户信息，那么去登录
      // this.client = 'patient';
      this.$router.replace('/login')
    } else {
      // 根据缓存的用户信息，区分角色
      const client = Number(JSON.parse(localStorage.getItem('userInfo') || '{}').userRole) === 0 ? 'patient' : 'center';

      this.$router.replace({
        path: client === 'patient' ? '/applyList' : '/infusion',
      });
    }
  }
}
</script>